<template>
    <v-container max-width="450">
      <div :class="['circular-bg', circularBgClass]" class="mx-auto my-2">
        <v-img
          class="inner-logo"
          max-width="50"
          :src="require('@/assets/favicon.png')"
        ></v-img>
      </div>
      <v-form @submit.prevent="resetPassword">
        <v-card
          class="mx-auto pa-6 pb-8"
          elevation="8"
          max-width="448"
          rounded="lg"
        >
          <div class="text-subtitle-2 text-medium-emphasis">New Password</div>
    
          <v-text-field
            density="compact"
            prepend-inner-icon="mdi-lock-outline"
            required
            solo
            outlined
            v-model="password"
            :type="showPassword1 ? 'text' : 'password'"
            :append-icon="showPassword1 ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassword1 = !showPassword1"
            label="New Password"
          ></v-text-field>
  
          <div class="text-subtitle-2 text-medium-emphasis">Confirm Password</div>
    
          <v-text-field
            density="compact"
            prepend-inner-icon="mdi-lock-outline"
            required
            solo
            outlined
            v-model="confirmPassword"
            :type="showPassword2 ? 'text' : 'password'"
            :append-icon="showPassword2 ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassword2 = !showPassword2"
            label="Confirm Password"
          ></v-text-field>
    
          <v-btn
            class="my-2"
            color="primary"
            size="large"
            rounded
            block
            @click="resetPassword"
          >
            Reset Password
          </v-btn>
    
        </v-card>
      </v-form>
      <v-snackbar :color="snackColor" v-model="snackbar" :timeout="2000">{{ response }}</v-snackbar>
    </v-container>
  </template>
  
  <script>
  import firebase from 'firebase/app';
  import 'firebase/auth';
  
  export default {
    data() {
      return {
        password: '',
        confirmPassword: '',
        showPassword1: false,
        showPassword2: false,
        actionCode: '',
        snackbar: false,
        response: '',
        snackColor: "black",
      };
    },
    created() {
      const urlParams = new URLSearchParams(window.location.search);
      this.actionCode = urlParams.get('oobCode');
    },
    computed: {
      circularBgClass() {
        return this.darkTheme ? 'circular-bg-dark' : 'circular-bg-light';
      }
    },
    methods: {
      async resetPassword() {
        if (this.password !== this.confirmPassword) {
          this.snackbar = true;
          this.snackColor = "info";
          this.response = "Passwords do not match";
          // alert('Passwords do not match');
          return;
        }
        try {
          await firebase.auth().confirmPasswordReset(this.actionCode, this.password);
          this.snackbar = true;
          this.snackColor = "success";
          this.response = "Password has been reset successfully";
          // alert('Password has been reset successfully');
          this.$router.push('/login');
        } catch (error) {
          this.snackbar = true;
          this.snackColor = "error";
          this.response = "Error resetting password";
          console.error('Error resetting password:', error);
          // alert(error.message);
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .fill-height {
    height: 100%;
  }
  </style>
  